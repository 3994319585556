:root {
    --themeColor: #0F3695;
    --darkBlue: #0F3695;
    --lightBlue: #068BDC;
    --gradient: linear-gradient(180deg, rgba(198, 174, 117, 1) 0%, rgba(97, 84, 71, 1) 100%);
    --silverColor: linear-gradient(180deg, rgba(255, 255, 255, 1) 30%, rgba(229, 239, 254, 0.4) 100%);
    // --silverColor: linear-gradient(to bottom, #E5EFFE 100%, #CAD3E7 0%);

    --white: #FFFFFF;
    --textPalceholder: #87E1FF;
}