@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1300px;
  }
}

@media (max-width: 1399px) {

  .common-space {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .workers-man>span {
    bottom: -222px !important;
  }

  .nav-bar-list {
    gap: 20px !important;
  }
}

@media (max-width: 1299px) {

  .banner-heading {
    line-height: 68px !important;
    font-size: 70px !important;
  }

  .banner-heding-sub {
    line-height: 38px !important;
    font-size: 40px !important;
  }
}

@media (max-width: 1199px) {

  .nav-bar .social-icon {
    display: none !important;
  }

  .logo svg {
    width: 140px !important;
  }

  .banner-content-wrapper {
    margin-left: 0 !important;
  }

  .nav-bar-list {
    gap: 15px !important;
  }

  .main-heading {
    font-size: 30px !important;
  }

  .sub-heading {
    font-size: 18px !important;
  }

  .choose-heading {
    font-size: 24px !important;
  }

  p {
    font-size: 18px !important;
  }

  .Choose-digital {
    padding: 40px 0 0 0 !important;
  }

  .experiance-heading {
    width: 65% !important;
  }

  .project-content {
    & p {
      font-size: 14px !important;
    }
  }

  .project-wapper {
    width: 250px !important;
  }
}

@media (max-width: 991px) {


  .nav-bar-list {
    display: none !important;
    z-index: 110;
  }

  .toggleBtn {
    margin: 0 5px;
    display: block !important;
    cursor: pointer;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);
  }

  .toggleBtn svg {
    color: var(--lightBlue);
  }

  .showToggle {
    position: absolute !important;
    top: 63px;
    left: 0;
    background: var(--white) !important;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);
    color: var(--white) !important;
    width: 100% !important;
    display: block !important;
    box-shadow: 0px 5px 15px #0ea3594f;
  }

  .showToggle div {
    border-bottom: 1px solid var(--white);
    outline: none;
    width: 100%;
    // padding: 20px 10px;
  }

  .showToggle.nav-bar-list a {
    background: none;
    padding: 10px 15px !important;
    display: flex;
    width: 100%;
  }

  .nav-bar-list a:hover::before,
  .nav-bar-list a.active::before {
    background: transparent !important;
  }

  .showToggle a:hover,
  .showToggle .active {
    color: var(--lightBlue);
  }

  .sticky.py-2 {
    transition: all 0.4s ease-in-out;
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
  }

  header {
    padding: 5px 0 !important;
  }

  section#home {
    height: auto !important;
    min-height: auto !important;
  }



  // adding css 



  .banner-content-wrapper {
    padding-top: 100px;
    padding-bottom: 130px;
    flex-wrap: wrap;
  }

  .about-rows {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .about-content-wrapper {
    display: flex;
    flex-direction: column;
    text-align: justify;
    padding: 0 20px;
  }

  .text-align {
    text-align: center;
  }

  .about-2 {
    top: -30px !important;
    right: 0 !important;
    text-align: end;
  }

  .bg-added {
    height: 200px !important;
  }

  .product-wrapper {
    width: 75% !important;
  }

 

  .common-space {
    padding: 30px 0 !important;
  }

  .experiance-heading {
    width: 50% !important;
  }

  .project-wapper {
    width: 48% !important;
  }

  .porject-img {
    text-align: center;
    margin-top: 30px;
  }

  .footer-row {
    flex-direction: column !important;
    flex-wrap: wrap !important;
    gap: 15px;

    &>div {
      justify-content: center;
      display: flex;

    }
  }

  .containerContact {
    padding-top: 60px !important;
    padding-bottom: 40px !important;
  }

  .f-logo-wrap {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
  }

  .portfolio-heading {
    text-align: center;
  }

  .heading-content {
    & .img-container {
      margin-bottom: 25px;
    }
  }

}

@media (max-width: 768px) {

  .banner-content-wrapper {
    justify-content: center;
    text-align: center;

    &>div.silverColor {
      font-size: 32px !important;
    }

    & .banner-text {
      text-align: center;

      & p {
        width: 70%;
        margin: auto;
      }

      & span {
        justify-content: center;
        margin: 20px 0;
      }
    }


  }

  .main-heading {
    font-size: 26px !important;
  }

  .about-2 {
    display: none !important;
  }

  .content-about-2 {
    text-align: center;

    & span {
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .experiance-heading {
    width: 68% !important;
  }

  .project-wapper {
    width: 100% !important;
    height: 120px !important;
  }

  .containerContact {
    padding: 35px 15px !important;
  }

  .contact-info {
    gap: 15px !important;
  }

  .footer-list {
    display: none !important;
  }

  .client-wrap {
    gap: 40px !important;
    padding: 30px 10px !important;
  }

  .achieve-box {
    padding: 0 15px !important;
    margin-top: 25px !important;
    height: 120px !important;
  }

  .product-wrapper {
    width: 100% !important;
  }
}

@media (max-width: 576px) {

  #home .social-link {
    display: none;
  }

  .footer-revised>* {
    display: flex;
    justify-content: center;
  }

  .footer-op {
    text-align: center;
  }

  .experiance-heading {
    width: 65% !important;
    font-size: 25px !important;
  }

  .product-wrapper-tabs>span {
    width: 40% !important;
  }

  .content-about-2 {
    &>div {
      font-size: 22px !important;
    }

    & p {
      text-align: center;
    }
  }

  .banner-heading {
    line-height: 50px !important;
    font-size: 50px !important;
  }

  .banner-heding-sub {
    line-height: 35px !important;
    font-size: 35px !important;
  }

  .banner-text {
    & p {
      width: 100%;
      margin: auto;
      text-align: center;
      text-wrap: balance;
    }
  }

  .banner-content-wrapper {
    gap: 10px !important;
    padding: 50px 0 !important;
  }

}

@media (max-width: 480px) {
  .about-content-wrapper {
    align-items: flex-start !important;
  }

  header .header-btn {
    display: none !important;
  }

  .providing {
    font-size: 18px !important;
  }

  .main-heading {
    font-size: 25px !important;
    text-align: center;
  }

  p {
    font-size: 14px !important;
    text-align: justify;
  }

  .contact-info {
    gap: 15px !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: inherit !important;
  }

  .change-Btn,
  .whatsapp {
    width: 60px !important;
  }

  .product-wrapper {
    width: 100% !important;
  }
}


@media (max-width: 420px) {

  .banner-content-wrapper>div.silverColor {
    font-size: 25px !important;
  }

  .choose-heading {
    font-size: 22px !important;
  }
}

@media (max-width: 480px) {}

@media (max-width: 400px) {

  .before_after::after,
  .before_after::before {
    content: "";
    position: absolute;
    width: 30px;
    height: 1px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: var(--darkColor);
  }

  .before_after::before {
    right: -35px;
  }

  .before_after::after {
    left: -35px;
  }

  .before_after_white::after,
  .before_after_white::before {
    content: "";
    position: absolute;
    width: 30px;
    height: 1px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: var(--white);
  }

  .before_after_white::before {
    right: -35px;
  }

  .before_after_white::after {
    left: -35px;
  }

  .after::before,
  .after::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 1px;
    top: 0px;
    bottom: 0;
    margin: auto;
    background-color: var(--darkColor);
  }

  .after::before {
    right: 100px;
  }

  .after::after {
    left: 100px;
  }

  .project-other .project-bg {
    position: absolute;
    bottom: 0px;
    right: 20px !important;
    width: 100px !important;
  }
}

/***************** RTL DIrection*************************/

@media (max-width: 1399px) {}

@media (max-width: 1199px) {

  .rtl .banner-content-wrapper {
    margin-right: 0 !important;
  }
}

@media (max-width: 991px) {
  .rtl .particals {
    right: 900px;
  }

  .rtl .worker-logo {
    left: 100px !important;
    right: auto !important;
    margin: auto;
  }

  .rtl .after::before {
    content: "";
    position: absolute;
    width: 60px;
    height: 1px;
    top: 0px;
    bottom: 0;
    left: 130px;
    right: auto !important;
    margin: auto;
    background-color: var(--darkColor);
  }
}

@media (max-width: 768px) {
  .rtl .particals {
    right: 700px;
  }
}

@media (max-width: 576px) {
  
  .rtl .banner-heading {
    line-height: 50px !important;
    font-size: 40px !important;
  }

  .rtl .banner-heding-sub {
    line-height: 45px !important;
    font-size: 35px !important;
  }
}

@media (max-width: 480px) {

  .rtl .abtColContent {
    order: 2;
    padding-left: 0px !important;
  }

  .rtl .particals {
    right: 470px;
  }
}

@media (max-width: 400px) {

  .rtl .particals {
    right: 400px;
  }

  .rtl .before_after::before {
    right: auto !important;
    left: -35px;
  }

  .rtl .before_after::after {
    left: auto !important;
    right: -35px;
  }

  .rtl .after::before {
    right: auto !important;
    left: 100px !important;
  }

  .rtl .after::after {
    left: auto !important;
    right: 100px !important;
  }

  .rtl .project-other .project-bg {
    position: absolute;
    bottom: 0px;
    right: auto !important;
    left: 20px !important;
    width: 100px !important;
  }
}