@import 'variables';
@import 'fonts';

html {
    position: relative;
    width: 100%;
}

.main-artical {
    overflow-x: hidden;
}

body {
    position: relative !important;
    font-family: var(--regular);
    font-style: normal;
    font-size: 15px;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    background-color: #F1F5FC;
}

img {
    max-width: 100%;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0
}

li {
    list-style: none
}

a {
    text-decoration: none;
    cursor: pointer;
}

a,
.btn {
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

a:focus,
.btn:focus {
    text-decoration: none;
    outline: none
}

.title-container {
    a {
        color: white;
    }
}

p {
    font-size: 16px;
    font-family: var(--regular);
    // padding-bottom: 15px;
}

*::-moz-selection {
    background: var(--darkBlue);
    color: #fff;
    text-shadow: none
}

*::-moz-selection {
    background: var(--darkBlue) none repeat scroll 0 0;
    color: #fff;
    text-shadow: none
}

::selection {
    background: var(--darkBlue);
    color: #fff;
    text-shadow: none
}

.gradient {
    background: var(--gradient);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;

}

.themeColor {
    background: var(--themeColor);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.silverColor {
    background: var(--silverColor);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}


.Box-shadow {
    -webkit-box-shadow: 0px 40px 40px -25px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 40px 40px -25px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 40px 40px -25px rgba(0, 0, 0, 0.2);
}

.common-space {
    padding-top: 50px;
    padding-bottom: 50px;
}

.heading-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.social-icon {
    & svg {
        box-shadow: 0px 8px 10px rgba(15, 54, 149, 0.1);
    }
}

iframe {
    width: 100%;
    height: 320px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.after {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        width: 60px;
        height: 1px;
        top: 0px;
        bottom: 0;
        left: 130px;
        margin: auto;
        background-color: var(--darkColor);
    }
}


.before_after {
    position: relative;

    &::after,
    &::before {
        content: '';
        position: absolute;
        width: 60px;
        height: 1px;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: var(--darkColor);
    }

    &::after {
        left: -70px;
    }

    &::before {

        right: -70px;
    }
}

.bg-setting {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.text-align {
    text-align: end;
}

.hover-color {
    background: var(--darkBlue) !important;

    & svg {
        [fill] {
            fill: var(--white);
        }
    }

    & span {
        color: var(--white) !important;
    }
}

.loading-btn {
    font-size: 18px;
    font-family: var(--semiBold);
    color: var(--white);
}


.spinner {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 3px solid;
    border-color: var(--darkBlue) transparent;
    animation: spin 1s infinite ease-out;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

footer {
    background-repeat: no-repeat;
    background-position: right 5px;
}



/***************** RTL DIrection*************************/

html[dir='rtl'] {
    position: relative !important;
    width: 100%;
    overflow-x: hidden;
}

body .rtl {
    position: relative !important;
    font-family: var(--arRegular) !important;
    font-style: normal;
    font-size: 15px;
    overflow-x: hidden;


}

.rtl .nav-bar-list a,
.rtl .footer-list a,
.rtl label {
    font-family: var(--arSemiBold) !important;
}

.rtl .nav-bar-list a.active {
    font-family: var(--arExtraBold) !important;
}

.rtl .loading-btn,
.rtl .Toastify__toast-body>div,
.rtl .field-box input::placeholder,
.rtl textarea::placeholder,
.rtl p,
.rtl .unList span,
.rtl input[type="file"]::after,
.rtl button:hover span,
.rtl .footer-op {
    font-family: var(--arRegular) !important;
}

.rtl button span,
.rtl button div,
.rtl .secondary-heading {
    font-family: var(--arMedium) !important;
}

.rtl .contact-social-links {
    position: relative;
    right: 15px;
}


.rtl .header-btn span,
.rtl .contact-text {
    direction: ltr;
}

.rtl .contact-text {
    font-family: var(--arSemiBold);
    text-align: end !important;
}

.rtl .change-Btn {
    position: fixed;
    bottom: 0;
    right: auto !important;
    left: 0;
}

.rtl .whatsapp {
    position: fixed;
    bottom: 0;
    left: auto !important;
    right: 0;
    z-index: 1;
}

.rtl .contact-svg,
.rtl .contact-img,
.rtl .header-btn svg,
.rtl .click-btn svg,
.rtl .service-list svg,
.rtl .service-list-container svg,
.rtl .successImgCol img,
.rtl .footer-list svg,
.rtl button svg,
.rtl .project-bg,
.rtl .worker-img,
.rtl .about-2,
.rtl .porject-img {
    transform: scaleX(-1) !important;
    -webkit-transform: scaleX(-1) !important;
    -moz-transform: scaleX(-1) !important;
    -ms-transform: scaleX(-1) !important;
    -o-transform: scaleX(-1) !important;
}

.rtl .text-align {
    text-align: start;
}

.rtl .banner-content-wrapper {
    margin-left: 0 !important;
    margin-right: 80px !important;
}

.rtl .banner-heading {
    font-size: 70px !important;
}

.rtl .banner-heding-sub {
    font-size: 40px !important;
}

//  adding 
.rtl #home {
    position: relative;
    background-image: url(../images/png/banner-ar.png) !important;
}

#home::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.1;
}

.rtl .yellow::before {
    right: auto !important;
    left: 3px !important;
}

.rtl .white::before {
    left: auto !important;
    right: 3px !important;
}

.rtl .after::after {
    content: "";
    position: absolute;
    width: 60px;
    height: 1px;
    top: 0px;
    bottom: 0;
    left: auto;
    right: 80px;
    margin: auto;
    background-color: var(--darkColor);
}

.rtl .about-img-wrap-2 {
    right: auto !important;
    left: -50px;
}

.rtl .about-img-wrap-1 {
    left: auto !important;
    right: -50px;
}

.rtl .Choose-digital {
    position: relative;
    background-color: var(--gray);
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 40px 20px 0px 30px;
    border-left: 1px solid #D8DBEF;
}

.rtl .project-other .project-bg {

    right: auto !important;
    left: 40px;
}

.rtl .workers-man>span {
    right: auto !important;
    left: -50px;
    bottom: -273px !important;
}

.rtl .worker-logo {
    position: absolute;
    bottom: -5px;
    left: 100px !important;
    right: 0 !important;
    margin: auto;
}

.rtl .about-2 {
    left: 50px !important;
    right: auto !important;
    text-align: start !important;
}